<template>
  <div :id="event.id" style="display: flex">
    <div
      class="shine"
      :class="{ yellow: event.triggered.length > 0 }"
    ></div>
    <div
      class="facecard-wrap"
      :class="{ 'active-yellow': chosen }"
      @click.left="openDetailCard"
    >
      <div class="container-img">
        <div class="photo">
          <img v-lazy="photoObj">
        </div>
        <div class="snapshot">
          <img v-lazy="featurePhotoObj">
          <span v-if="isMatched" class="score">{{ getScore(sortTriggered[0].score) }}%</span>
        </div>
      </div>
      <div class="container-info">
        <div v-if="isMatched" class="person">
          <div class="icon">
            <img :src="getGenderIcon(sortTriggered[0].gender)" alt="">
          </div>
          <div class="name">{{ sortTriggered[0].name }}</div>
        </div>
        <div v-else class="person">
          <div class="icon">
            <img src="@/assets/icons/user-yellow-L.svg" alt="">
          </div>
          <div class="name">{{ $t('unknown') }}</div>
        </div>
        <div class="time" :class="{ active: chosen }">
          <div>{{ detectTime }}</div>
        </div>
        <div v-if="isMatched" class="info">
          <TooltipElement :content="$t('history_fr_age'/*年齡*/)" alwaysShow>
            <img src="@/assets/icons/person-age.svg" alt="">
          </TooltipElement>
          <span>{{ getAge(sortTriggered[0].birthday) }}</span>
        </div>
        <div class="info video-title">
          <TooltipElement :content="$t('history_fr_device'/*設備*/)" alwaysShow>
            <img src="@/assets/icons/Camera.svg" alt="">
          </TooltipElement>
          <TooltipElement :content="getDeviceTitleId(event.user.id)">
            <span>{{ getDeviceTitleId(event.user.id) }}</span>
          </TooltipElement>
        </div>
        <div v-if="isMatched && sortTriggered[0].tag.length > 0" class="tags">
          <TooltipElement :content="$t('history_fr_tag'/*人物標記*/)" alwaysShow>
            <img src="@/assets/icons/tag.svg" alt="">
          </TooltipElement>
          <TooltipElement :content="getTagNameStr(sortTriggered[0].tag, frTagList)" alwaysShow>
            <div class="wrap-tags">
              <span v-for="(tag, index) in getTagNameList(sortTriggered[0].tag, frTagList)" :key="index">{{ tag }}</span>
            </div>
          </TooltipElement>
        </div>
        <div v-if="event.description" class="info push-content">
          <TooltipElement :content="$t('history_fr_remark'/*備註*/)" alwaysShow>
            <img src="@/assets/icons/note.svg" alt="">
          </TooltipElement >
          <TooltipElement :content="event.description">
            <span>{{ event.description }}</span>
          </TooltipElement>
        </div>
      </div>
      <div class="container-action">
        <TooltipElement :content="$t('search_misjudgment'/*誤判*/)" placement="top" alwaysShow>
          <div class="mis-judgment" :class="{ error: event.misjudged }" @click.stop="setEventMisjudged(event)">
            <img :src="getMisjudgedIcon(event.misjudged)" alt="">
          </div>
        </TooltipElement>
      </div>
      <!-- <div class="pop-up-menu" v-if="popupOpen">
        <div
          class="option location"
          @click="showLocation($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/userAccount.svg" alt="" /></div>
          <div>{{ $t('event_show_position') }}</div>
        </div>
        <div
          v-if="permissionV2.lprEventAccess > 0"
          class="option historyRecord"
          @click="showHistoryRecord($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/clock.svg" alt="" /></div>
          <div>{{ $t('evnet_show_history') }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { apiEditBoviaFrEvent } from '@/api/index.js'
import { 
  getGenderIcon, 
  getMisjudgedIcon, 
  getTagNameStr, 
  getTagNameList 
} from '@/utils/index.js'
import { getAge, getScore, formatTime } from '@/utils/lib.js'

export default {
  name: 'FaceCard',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  data() {
    return {
      chosen: false,
      clicks: 0,
      clickTimer: null,
      isShowTooltip: false,
      defaultUnknow: require('@/assets/icons/unknow-user.svg')
    }
  },
  props: {
    event: Object
  },
  created() {
    this.$bus.$on('closePopUpMenu', () => {
      this.popupOpen = false
    })
  },
  mounted() {
    this.checkCardChosenSts()
  },
  beforeDestroy() {
    this.$bus.$off('closePopUpMenu')
  },
  computed: {
    ...mapState([
      'userList',
      'frTagList',
      'selectedUsers',
      'shining',
      'eventCardDetailObj',
      'RightMenuOpen',
      'liveList',
      'codeBooks',
      'permissionV2'
    ]),
    ...mapGetters('account', ['getDeviceTitleId']),
    isMatched() {
      return this.event.triggered.length > 0
    },
    shiningSts() {
      if (this.event.chasing == 1) {
        if (this.event.chasingStartTime && this.event.chasingEndTime) {
          return false
        }
        return this.shining
      }
      return false
    },
    bSelected() {
      return this.$store.state.selectedEvent.id === this.event.id
    },
    detectTime() {
      return formatTime(this.event.detectTime)
    },
    photoObj() {
      return {
        src: this.event.photoUrl,
        error: this.defaultUnknow,
      }
    },
    sortTriggered() {
      const temp = [...this.event.triggered]
      return temp.sort((a, b) => a.rank - b.rank)
    },
    featurePhotoObj() {
      let featPhotoUrl = null
      if (this.event.triggered.length == 0) featPhotoUrl = null
      else if (this.event.triggered.length == 1) featPhotoUrl = this.event.triggered[0].featurePhotoUrl
      else featPhotoUrl = this.sortTriggered[0].featurePhotoUrl
      
      return {
        src: featPhotoUrl,
        error: this.defaultUnknow,
      }
    },
  },
  watch: {
    eventCardDetailObj() {
      if (!('user' in this.eventCardDetailObj)) {
        this.chosen = false
      }
      if (
        this.event.detectTime == this.eventCardDetailObj.detectTime &&
        this.event.id == this.eventCardDetailObj.id
      ) {
        this.chosen = true
        const el = document.getElementById(this.event.id)
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        this.chosen = false
      }
    }
  },
  methods: {
    ...mapMutations('history', ['updateEventPlate']),
    getAge,
    getScore,
    getGenderIcon,
    getMisjudgedIcon,
    getTagNameStr,
    getTagNameList,
    checkCardChosenSts() {
      // mounted 確認有沒有選中的event 有就讓那張卡呈現選中的樣式
      // 打開detail modal：bus emit 先讓所有eventcard回到初始狀態 再選中特定的卡
      // 關掉detail modal：bus emit 讓所有eventcard回到初始狀態
      if (Object.keys(this.eventCardDetailObj).length == 0) {
        this.chosen = false
        return
      }
      this.chosen = this.eventCardDetailObj.id === this.event.id ? true : false
    },
    openDetailCard() {
      this.clicks++
      if (this.clicks === 1) {
        // 2秒後重設 clicks = 0, 避免使用者短時間快速點擊
        this.clickTimer = setTimeout(() => {
          this.clicks = 0
        }, 2000)

        // 打開跳窗
        // 新舊事件跳窗型態不同 就要判斷哪個開哪個關 相同就單純更新eventDetailObj和liveVideoUrl即可
        if (this.event.chasing == 1 || this.event.sos > 0) {
          this.syncUserTree()
          // 22.02.07 tingyu 把單路userID放到vuex變數
          this.$store.commit('updateSingleUrlUserID', this.event.user.id)
        }
        this.$bus.$emit('closePopUpMenu')
        this.$store.dispatch('handleEventModal', this.event)

        if (this.event.chasing == 1 || this.event.sos > 0) {
          this.$bus.$emit(
            'sosCall',
            'group',
            this.event.user.id,
            this.event.groupId
          )
        }
      }
    },
    syncUserTree() {
      // 點擊right bar追車事件時 要同步把左邊的該user也勾選起來
      let idx = this.selectedUsers.findIndex(
        (user) => user.id == this.event.user.id
      )
      if (idx >= 0) this.$store.commit('setTrackUser', this.selectedUsers[idx])
      else {
        // 點擊未勾選裝置的追車事件，自動將其勾選(加入selectedUsers --> reloadGroupUserTree)，並將其設為trackUser
        let clickUser = this.userList.find(
          (item) => item.id === this.event.user.id
        )
        if (clickUser) {
          // 須將資料處理成與selectedUser, trackUser一樣的格式
          let user = {
            id: clickUser.id,
            name: clickUser.video.title,
            groupId: clickUser.groupId,
            label: clickUser.video.title + ' (' + clickUser.id + ')'
          }
          this.$store.commit('addSelectedUser', user)
          this.$store.commit('setTrackUser', user)
        }
      }
      this.$bus.$emit('panToTrackUser') // 地圖移動到TrackUser最新的位置
    },
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaFrEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.facecard-wrap {
  display: flex;
  box-sizing: border-box;
  width: 362px;
  background-color: #19223b;
  border: 1px solid #4A5C78;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  padding: 8px;
  margin-bottom: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
}

.active-yellow {
  background-color: #384c83;
  border-color: #FFC600;
  border-width: 3px 3px 3px 0;
}

.active-red {
  background-color: #426393;
  border-color: #F94144;
  border-width: 3px 3px 3px 0;
}

.facecard-wrap.active {
  background-color: #426393;
}

.facecard-wrap:first-child {
  margin-top: 20px;
}

.shine {
  border-left: 10px solid #4A5C78;
  margin-bottom: 10px;
  border-radius: 5px 0px 0px 5px;
  visibility: visible;
}

.red {
  border-left: 10px solid #f94144;
}

.yellow {
  border-left: 10px solid #ffc600;
}

.gray {
  border-left: 10px solid #4A5C78;
}

.none {
  border-left: 10px solid #f9414466;
}

.photo, .snapshot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  border-radius: 3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
  img[lazy=error] {
    width: 60%;
    height: 60%;
  }
}

.photo {
  width: 96px;
  height: 96px;
  margin-bottom: 4px;
}

.snapshot {
  width: 96px;
  height: 96px;
  position: relative;
}

.snapshot > .score {
  position: absolute;
  left: 4px;
  bottom: 0px;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.container-info {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: calc(100% - 126px);
  padding: 0 4px 0 8px;
}

.sos-chasing {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F94144;
  border: 4px solid #F94144;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 3px 0;
  color: #ffffff;
  animation: bgchange 1.5s infinite;
}

.sos-chasing img {
  margin-right: 8px;
}

.sos-chasing-title {
  font-family: 'Noto Sans TC', Roboto;
  font-size: 34px;
  line-height: 46px;
}

@keyframes bgchange {
  0% {
    background-color: #F94144;
  }
  50% {
    background-color: #F9414433;
  }
  100% {
    background-color: #F94144;
  }
}

.person {
  position: relative;
  display: flex;
  align-items: center;
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    font-size: px2rem(24);
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #FFE99F;
  }
}

.time {
  & > div {
    display: inline-block;
    height: 25px;
    background: #4A5C78;
    border-radius: 17px;
    padding: 0px 12px;
    font-size: px2rem(14);
    line-height: 25px; 
    letter-spacing: 0.5px;
  }
  &.active > div {
    background: #282942;
  }
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  &.push-content {
    align-items: flex-start;
  }
}

.info img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.video-title {
  width: 100%;
  display: flex;
  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.separater {
  width: 100%;
  height: 1px;
  background: #ffffff33;
  margin: 6px 0;
}

.urgent {
  left: -2px;
}

.tags {
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  overflow: hidden;
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  
  .wrap-tags {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 24px;
    span {
      background: #ffffff33;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding: 0 12px;
      border-radius: 12px;
      white-space: nowrap;
    }
  }
}

.push-message {
  font-size: 16px;
  letter-spacing: 1.5px;
  color: #ffffff;
}

.message {
  display: flex;
  align-items: center;
}

.message img {
  margin-right: 8px;
  vertical-align: bottom;
}

.push-content {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.push-content img {
  position: relative;
  margin-right: 4px;
  top: 3px;
}

.push-content span {
  /* width: 100%; */
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.container-action {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mis-judgment {
  opacity: 0.2;
  &.error {
    opacity: 1;
  }
}

.mis-judgment img {
  vertical-align: middle;
}

.pop-up-menu {
  background-color: #224974;
  padding: 2px 5px 2px 1px;
  gap: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  position: absolute;
  top: 13px;
  right: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option {
  display: flex;
  gap: 10px;
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.8px;
  height: 50%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
}

.option img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.location:hover {
  background-color: #577590;
  border-radius: 3px 3px 0px 0px;
}

.historyRecord:hover {
  background-color: #577590;
  border-radius: 0px 0px 3px 3px;
}

.note {
  line-height: 24px;
}

.info-icon {
  transform: rotate(90deg);
  color: #ffffff;
  cursor: pointer;
}
</style>
